require('./bootstrap');
//import { getBreakpointValue, getCurrentBreakpoint } from './screensize';

import Alpine from 'alpinejs';

import 'flowbite';

window.Alpine = Alpine;
/*
window.getBreakpointValue = getBreakpointValue;
window.getCurrentBreakpoint = getCurrentBreakpoint;
*/
Alpine.start();

